import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { BlogJsonLd } from 'gatsby-plugin-next-seo';
import { get } from 'lodash';
import Layout from '../components/Layout';
import ItemsList from '../components/ItemsList';
import useSiteMetadata from '../components/SiteMetadata';



export const IndexPageTemplate = ({
  heading,
  subheading,
  latestPosts,
  projects,
  events,
}) => {

  return (
    <div className="main home-page">
      <section className="narrow-hero grid-container flex-start">
        <div className="hero-titles col-xs-12 col-sm-10 off-sm-1 col-md-12 off-md-0">
          <h1 className="page-title">{heading}</h1>
          <h2 className="page-subtitle">{subheading}</h2> 
          <img className="hero-titles--image" src="/img/logos-merging-16x9.gif" alt="An animation of Wunderman Thompson and VMLY&R logos merging to form the new VML logo." />
        </div>
      </section>
      <section className="grid-container flex-start">
        <div className="col-xs-12 col-sm-10 off-sm-1 col-md-12 off-md-0">
          <h2 className="section-title title-white">Latest posts</h2>
          <ItemsList initialItems={latestPosts} cardColumns={6} isMixedLayout={true} />
          <div className="button-wrapper">
            <Link className="button-primary button-blog relative hover-border-bottom-swipe-in pseudo-bg-color-blog" to="/blog">
              More posts
            </Link>
          </div>
        </div>
      </section>
      <section className="grid-container flex-start">
      <div className="col-xs-12 col-sm-10 off-sm-1 col-md-12 off-md-0">
          <h2 className="section-title title-white-desktop">Events</h2>
          <ItemsList initialItems={events} cardColumns={6} isMixedLayout={true} />
          <div className="button-wrapper">
            <Link className="button-primary button-events relative hover-border-bottom-swipe-in pseudo-bg-color-events" to="/events">
              More events
            </Link>
          </div>
        </div>
      </section>
      <section className="grid-container flex-start">
        <div className="col-xs-12 col-sm-10 off-sm-1 col-md-12 off-md-0">
          <h2 className="section-title">Open source projects</h2>
          <ItemsList initialItems={projects} cardColumns={12} />
          <div className="button-wrapper">
            <Link className="button-primary button-projects relative hover-border-bottom-swipe-in pseudo-bg-color-open-source" to="/open-source">
              More projects
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  heading: PropTypes.string,
  boldheading: PropTypes.string,
  subheading: PropTypes.string,
  latestPosts: PropTypes.array,
  projects: PropTypes.array,
  events: PropTypes.array,
};

const IndexPage = ({ data }) => {
  const { markdownRemark: page } = data;
  const { edges: latestPosts } = data.latestPosts;
  const { edges: projects } = data.projects;
  const { edges: latestEvents } = data.latestEvents;
  const { edges: pastEvents } = data.pastEvents;

  const { title, description } = useSiteMetadata();
  const lastestPostsHeadlines = latestPosts.map(post => ({
    headline: get(post, 'node.frontmatter.title'),
  }));

  return (
    <Layout pageType="HomePage">
      <BlogJsonLd
        headline={title}
        posts={lastestPostsHeadlines}
        authorName="Cognifide"
        authorType="Organization"
        description={description}
      />
      <IndexPageTemplate
        heading={page.frontmatter.heading}
        subheading={page.frontmatter.subheading}
        latestPosts={latestPosts}
        projects={projects}
        events={[...latestEvents, ...pastEvents].slice(0, 3)}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate($date: Date!) {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        heading
        subheading
      }
    }
    latestPosts: allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date, frontmatter___title], order: DESC }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          ...BlogPostCardFragment
        }
      }
    }
    latestEvents: allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date, frontmatter___title], order: ASC }
      filter: {
        frontmatter: { templateKey: { eq: "event" }, date: { gte: $date } }
      }
    ) {
      edges {
        node {
          ...EventCardFragment
        }
      }
    }
    pastEvents: allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date, frontmatter___title], order: DESC }
      filter: {
        frontmatter: { templateKey: { eq: "event" }, date: { lt: $date } }
      }
    ) {
      edges {
        node {
          ...EventCardFragment
        }
      }
    }
    projects: allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___title], order: ASC }
      filter: { frontmatter: { templateKey: { eq: "project" } } }
    ) {
      edges {
        node {
          ...ProjectCardFragment
        }
      }
    }
  }
`;
